export enum AllLocales {
  EN_US = 'en-US',
  EN_CA = 'en-CA',
  FR_CA = 'fr-CA',
  EN_GB = 'en-GB',
  EN_AU = 'en-AU',
  FR_FR = 'fr-FR',
  EN_IE = 'en-IE',
  NL_NL = 'nl-NL',
  DE_DE = 'de-DE',
  ES_ES = 'es-ES',
  IT_IT = 'it-IT',
  PT_PT = 'pt-PT',
}

export const ALL_LOCALES_LIST: AllLocales[] = Object.values(AllLocales);

export enum PhraseLocales {
  EN_US = 'en_us',
  EN_CA = 'en_ca',
  FR_CA = 'fr_ca',
  EN_GB = 'en_gb',
  EN_AU = 'en_au',
  FR_FR = 'fr_fr',
  EN_IE = 'en_ie',
  NL_NL = 'nl-NL',
  DE_DE = 'de-DE',
  ES_ES = 'es-ES',
  IT_IT = 'it-IT',
  PT_PT = 'pt-PT',
}

export const PHRASE_US_PROJECT_ID = 'MzfcmlMFMjVF8tAyrLBQg2';

export const PHRASE_SEGMENTATION_ID = '2qPufBwI1vjrcGRHZUgic5';

export enum TranslationsEventTypes {
  WpToPhrase = 'WP_TO_PHRASE',
  PhraseToWp = 'PHRASE_TO_WP',
}

export const TEMPLATE_REGEXP = /{{(.*?)}}/;
