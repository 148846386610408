import { AllLocales } from '../constants/i18n';

export enum WpPostTypes {
  Page = 'page',
  Partial = 'partial',
  Recipe = 'recipe',
  CustomerSpotlight = 'customerSpotlight',
  Reusable = 'reusable',
}

export type WpBlockI18nLocaleContent = {
  source: Record<string, string>;
  translation: Record<string, string>;
};

export type WpBlockI18n = {
  [key in AllLocales]?: WpBlockI18nLocaleContent;
};

export type WpBlock = {
  name: string;
  attributes: {
    id?: string;
    i18n?: WpBlockI18n;
    [key: string]: any;
  };
  innerBlocks?: WpBlock[];
};

export type WpPostMeta = {
  id: string;
  global: {
    _locales: AllLocales[];
    _localesToTranslate: AllLocales[];
    _localesPublished: AllLocales[];
  };
  page: {
    _headerOptions: any;
    _amplitudePageType: string;
  };
  seo: {
    title: string;
    _isIndexed: any[];
  };
  attributes: {
    id?: string;
    i18n?: WpBlockI18n;
    [key: string]: any;
  };
} & {
  [key: string]: any;
};

export type WpPostWp = {
  title: string;
  slug: string;
  type: 'page' | 'partial' | 'recipe' | 'customer_spotlight';
  status: string;
  date: string;
  isSticky: boolean;
  categories: string[];
  author: string;
  excerpt: string;
};

export type WpPost = {
  pageId?: number;
  partialId?: number;
  recipeId?: number;
  customerSpotlightId?: number;
  wp: WpPostWp;
  meta: WpPostMeta;
  blocks: WpBlock[];
  partials: any;
  data: any;
};

export type WpPostKeys = keyof WpPost;
